import { MetadataJson, programs } from "@metaplex/js";
const {
  metadata: { Metadata },
} = programs;
import { $connection } from "state/connection";
import { fork } from "effector";
import { PublicKey, AccountInfo } from "@solana/web3.js";
import { $wallet } from "state/wallet";
import { gql } from "@apollo/client";
import { Nft } from "types";
import client from "../client";

export interface NFTData {
  name: string;
  symbol: string;
  updateAuthority: string;
  image: string;
  mintAddress: string;
}

const GET_NFT = gql`
  query GetNft($address: String!) {
    nft(address: $address) {
      name
      address
      image(width: 64)
      mintAddress
      updateAuthorityAddress
    }
  }
`;

interface GetNftData {
  nft: Nft;
}

export const fetchNFT = async (mint: string) => {
  return fetchNFTMetadataFromRPC(mint);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchNFTMetadataFromIndexer = async (mint: string) => {
  const pda = await Metadata.getPDA(mint);

  const {
    data: { nft },
  } = await client.query<GetNftData>({
    fetchPolicy: "cache-first",
    query: GET_NFT,
    variables: {
      address: pda,
    },
  });

  if (nft) {
    const scope = fork();
    const wallet = scope.getState($wallet);

    if (!wallet) {
      throw new Error(`Wallet isn't connected`);
    }

    if (nft.updateAuthorityAddress === wallet.publicKey.toBase58()) {
      return {
        name: nft.name,
        updateAuthority: nft.updateAuthorityAddress || undefined,
        image: nft.image,
        mintAddress: nft.mintAddress,
      } as NFTData;
    }
  }

  return undefined;
};

const fetchNFTMetadataFromRPC = async (mint: string) => {
  const scope = fork();
  const wallet = scope.getState($wallet);
  const connection = scope.getState($connection);

  if (!wallet) {
    throw new Error(`Wallet isn't connected`);
  }

  const pda = await Metadata.getPDA(mint);
  const accountInfo = await connection.getParsedAccountInfo(new PublicKey(pda));
  const metadata = new Metadata(
    mint,
    accountInfo?.value as AccountInfo<Buffer>
  );

  if (
    metadata &&
    metadata.data.updateAuthority === wallet.publicKey.toBase58()
  ) {
    const response = await fetch(metadata.data.data.uri);
    const result = (await response.json()) as MetadataJson;

    return {
      name: metadata.data.data.name,
      symbol: metadata.data.data.symbol,
      updateAuthority: metadata.data.updateAuthority,
      image: result.image,
      mintAddress: metadata.data.mint,
    } as NFTData;
  }

  return undefined;
};

interface GetNftsData {
  nfts: Nft[];
}

const GET_NFTS = gql`
  query GetNftsByMint($addresses: [PublicKey!]!) {
    nfts_by_mint_address(addresses: $addresses) {
      address
      mintAddress
      updateAuthorityAddress
    }
  }
`;

export const fetchNFTs = async (mintList: string[]) => {
  const {
    data: { nfts },
  } = await client.query<GetNftsData>({
    fetchPolicy: "cache-first",
    query: GET_NFTS,
    variables: {
      mintList,
      offset: 0,
      limit: 100000,
    },
  });

  const scope = fork();
  const wallet = scope.getState($wallet);

  if (!wallet) {
    throw new Error("Wallet isn't connected!");
  }

  return nfts
    .filter((nft) => {
      return nft.updateAuthorityAddress === wallet.publicKey.toBase58();
    })
    .map((nft) => {
      return nft.mintAddress;
    });
};
