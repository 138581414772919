import { actions } from "@metaplex/js";
import { PublicKey } from "@solana/web3.js";
import { revokeCollectionAuthorityAction } from "./actions";
import { $connection } from "state/connection";
import { $wallet } from "state/wallet";
import { fork } from "effector";

const { sendTransaction } = actions;

export interface RevokeCollectionAuthorityProps {
  collectionId: PublicKey;
}

export const revokeCollectionAuthority = async ({
  collectionId,
}: RevokeCollectionAuthorityProps) => {
  const scope = fork();
  const connection = scope.getState($connection);
  const wallet = scope.getState($wallet);

  if (!wallet) {
    throw new Error(`Wallet isn't connected`);
  }

  try {
    const revokeUpdateAuthorityTx = await revokeCollectionAuthorityAction({
      collectionId,
    });

    const txid = await sendTransaction({
      connection,
      wallet,
      txs: [revokeUpdateAuthorityTx],
    });

    await connection.confirmTransaction(txid, "max");
    await connection.getParsedTransaction(txid, "confirmed");

    return {
      txId: txid,
    };
  } catch (err) {
    throw err;
  }
};
