import { ApolloClient, InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

const GRAPHQL_ENDPOINT = "https://graph.holaplex.com/v1";
const client = new ApolloClient({
  uri: GRAPHQL_ENDPOINT,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          nfts: offsetLimitPagination(),
        },
      },
      Nft: {
        keyFields: ["address"],
      },
      Wallet: {
        keyFields: ["address"],
      },
      Creator: {
        keyFields: ["address"],
      },
      NftCreator: {
        keyFields: ["address"],
      },
      NftOwner: {
        keyFields: ["address"],
      },
      NftAttribute: {
        keyFields: ["traitType", "value"],
      },
    },
  }),
});

export default client;
