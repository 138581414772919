import { $network } from "./connection";
import { NFTStorage as NftStorageType } from "nft.storage";

// https://github.com/nftstorage/nft.storage/issues/876
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
const { NFTStorage } = require("nft.storage/dist/bundle.esm.min.js");

const NFT_STORAGE_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDk1MTBGOTBiNTA5M2M0QzU0RTgzMTQyMTA2YjAzOTk4QzgzNEMxNzQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NTEyOTE1MTYxOCwibmFtZSI6ImNvbGxlY3Rpb25zLm1ldGFwbGV4LmNvbSJ9.yiI0-UhUan2Jquy27FNSOvgG68gPnjPaa2g62X71tEM";

export const $storage = $network.map(
  () =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    new NFTStorage({
      token: NFT_STORAGE_TOKEN,
    }) as NftStorageType
);
