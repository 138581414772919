import { PublicKey } from "@solana/web3.js";
import { $walletAddress } from "state/wallet";
import {
  RevokeCollectionAuthority,
  Metadata,
  MetadataProgram,
} from "@metaplex-foundation/mpl-token-metadata";
import { fork } from "effector";

const REVOKE_COLLECTION_AUTHORITY_PUBKEY = new PublicKey(
  "AQdVjN6ESkryiRx4UWHnEt3hxfAW6FdSKwiBEgta5rJP"
);

export interface IRevokeCollectionAuthorityParams {
  collectionId: PublicKey;
}

export async function revokeCollectionAuthorityAction({
  collectionId,
}: IRevokeCollectionAuthorityParams) {
  const scope = fork();
  const walletAddress = scope.getState($walletAddress);

  if (!walletAddress) {
    throw new Error(`Wallet isn't connected`);
  }

  const dARecord = await MetadataProgram.findCollectionAuthorityAccount(
    collectionId,
    REVOKE_COLLECTION_AUTHORITY_PUBKEY
  );

  const metadataPDA = await Metadata.getPDA(collectionId);

  return new RevokeCollectionAuthority(
    {},
    {
      collectionAuthorityRecord: dARecord[0],
      newCollectionAuthority: REVOKE_COLLECTION_AUTHORITY_PUBKEY,
      updateAuthority: new PublicKey(walletAddress),
      metadata: metadataPDA,
      mint: collectionId,
    }
  );
}
