/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { fork } from "effector";
import { $connection } from "state/connection";
import bs58 from "bs58";
import { PublicKey, Connection } from "@solana/web3.js";
import { $wallet } from "state/wallet";
import { gql } from "@apollo/client";
import client from "../client";
import { NftCreator, Nft } from "types";
import { MintLayout, TOKEN_PROGRAM_ID, u64 } from "@solana/spl-token";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

const TOKEN_METADATA_PROGRAM = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

const MAX_NAME_LENGTH = 32;
const MAX_URI_LENGTH = 200;
const MAX_SYMBOL_LENGTH = 10;
const MAX_CREATOR_LEN = 32 + 1 + 1;
const MAX_CREATOR_LIMIT = 5;
const MAX_DATA_SIZE =
  4 +
  MAX_NAME_LENGTH +
  4 +
  MAX_SYMBOL_LENGTH +
  4 +
  MAX_URI_LENGTH +
  2 +
  1 +
  4 +
  MAX_CREATOR_LIMIT * MAX_CREATOR_LEN;
const MAX_METADATA_LEN = 1 + 32 + 32 + MAX_DATA_SIZE + 1 + 1 + 9 + 172;
const CREATOR_ARRAY_START =
  1 +
  32 +
  32 +
  4 +
  MAX_NAME_LENGTH +
  4 +
  MAX_URI_LENGTH +
  4 +
  MAX_SYMBOL_LENGTH +
  2 +
  1 +
  4;

export const getMintListFromCreatorId = async (firstCreatorAddress: string) => {
  return fetchFromRPC(firstCreatorAddress);
};

const fetchFromRPC = async (firstCreatorAddress: string) => {
  const scope = fork();
  const wallet = scope.getState($wallet);
  const connection = scope.getState($connection);

  if (!wallet) {
    throw new Error("Wallet isn't connected!");
  }

  const validMetadataAccounts = await connection.getProgramAccounts(
    TOKEN_METADATA_PROGRAM,
    {
      dataSlice: { offset: 33, length: 32 },
      filters: [
        { dataSize: MAX_METADATA_LEN },
        {
          memcmp: {
            offset: CREATOR_ARRAY_START,
            bytes: firstCreatorAddress,
          },
        },
        {
          memcmp: {
            offset: 1,
            bytes: wallet.publicKey.toBase58(),
          },
        },
      ],
    }
  );

  return validMetadataAccounts.map((metadataAccountInfo) =>
    bs58.encode(metadataAccountInfo.account.data)
  );
};

interface GetNftsData {
  nfts: Nft[];
  creator: NftCreator;
}

const GET_NFTS = gql`
  query GetNfts(
    $creators: [PublicKey!]!
    $updateAuthorities: [PublicKey!]!
    $limit: Int!
    $offset: Int!
  ) {
    nfts(
      creators: $creators
      updateAuthorities: $updateAuthorities
      limit: $limit
      offset: $offset
    ) {
      address
      mintAddress
      updateAuthorityAddress
      creators {
        address
        verified
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchFromIndexer = async (firstCreatorAddress: string) => {
  const scope = fork();
  const wallet = scope.getState($wallet);
  const connection = scope.getState($connection);

  if (!wallet) {
    throw new Error("Wallet isn't connected!");
  }

  const updateAuthority = wallet.publicKey.toBase58();

  const {
    data: { nfts },
  } = await client.query<GetNftsData>({
    fetchPolicy: "network-only",
    query: GET_NFTS,
    variables: {
      creators: [firstCreatorAddress],
      updateAuthorities: [updateAuthority],
      offset: 0,
      limit: 100000,
    },
  });

  return await validatedMintAccounts(
    nfts
      .filter((nft) => {
        return (
          nft.creators.length > 0 &&
          nft.creators[0].verified &&
          nft.creators[0].address === firstCreatorAddress
        );
      })
      .map((nft) => {
        return new PublicKey(nft.mintAddress);
      }),
    connection
  );
};

const chunkItems = <T>(items: T[]) =>
  items.reduce((chunks: T[][], item: T, index) => {
    const chunk = Math.floor(index / 100);
    chunks[chunk] = ([] as T[]).concat(chunks[chunk] || [], item);
    return chunks;
  }, []);

const validatedMintAccounts = async (
  mintAddress: PublicKey[],
  connection: Connection
): Promise<string[]> => {
  const chunks = chunkItems(mintAddress);
  return (
    await Promise.all(
      chunks.map(async (mintChunk) => {
        const metadataPDAs: PublicKey[] = [];

        for (const mint of mintChunk) {
          metadataPDAs.push(await Metadata.getPDA(mint));
        }

        const metadataAccountInfo = await connection.getMultipleAccountsInfo(
          metadataPDAs
        );

        const mintAccountsInfo = await connection.getMultipleAccountsInfo(
          mintChunk
        );

        return mintAccountsInfo.map((accountInfo, index) => {
          if (
            accountInfo !== null &&
            accountInfo.owner.equals(TOKEN_PROGRAM_ID) &&
            accountInfo.data.length === MintLayout.span &&
            metadataAccountInfo[index] !== null
          ) {
            const mintInfo = MintLayout.decode(accountInfo.data);
            const supply = u64.fromBuffer(mintInfo.supply).toNumber();
            if (supply === 1 && mintInfo.decimals === 0) {
              return mintChunk[index].toBase58();
            }
          }
          return null;
        });
      })
    )
  )
    .flat()
    .filter((mint) => mint) as string[];
};
