/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
import { PublicKey } from "@solana/web3.js";
import { ECollectionProgress } from "views/CreateCollection/CreateCollection.state";
import { RequestPayload } from "state/collection";
import { Pipeline } from "utils/pipeline";
import { $wallet } from "state/wallet";
import { fork } from "effector";

export const migrateCollection = async (
  collectionId: PublicKey,
  payload: RequestPayload,
  pipe: Pipeline<ECollectionProgress | null>
) => {
  pipe.setStep(ECollectionProgress.migrating_collection);
  const success = await startCollectionMigration(collectionId, payload);

  if (success) {
    pipe.setStep(ECollectionProgress.migrating_success);
  } else {
    pipe.setStep(ECollectionProgress.migrating_error);
    throw new Error(
      `Failed to begin the migration for Collection ID ${collectionId.toBase58()}.`
    );
  }
};

const API_ENDPOINT_CREATE_COLLECTION =
  "https://2d5hc1e0ri.execute-api.us-east-1.amazonaws.com/create-collection";

const MESSAGE =
  'Verify with Metaplex.\n\nClick "Sign" or"Approve" only means you have proved this wallet is owned by you.\n\nThis request will not trigger any blockchain transactions or generate a fee.\n\n nonce: ';

const startCollectionMigration = async (
  collectionMint: PublicKey,
  payload: RequestPayload
): Promise<boolean> => {
  const scope = fork();
  const wallet = scope.getState($wallet);

  if (!wallet || !wallet.signMessage) {
    throw new Error("Wallet isn't connected!");
  }

  payload.collection_id = collectionMint.toBase58();
  payload.nonce = Date.now();
  payload.message = MESSAGE + payload.nonce.toString();

  const encodedMessage = new TextEncoder().encode(payload.message);
  const signature = await wallet.signMessage(encodedMessage);

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ ...payload, signature }),
  };

  return await fetch(API_ENDPOINT_CREATE_COLLECTION, requestOptions).then(
    (response) => {
      return response.ok;
    }
  );
};
