import BN from "bn.js";
import {
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { actions, Connection, MetadataJson, Wallet } from "@metaplex/js";
import { createFilePack, METADATA_FILE_NAME } from "utils/arweave-cost";
import { Pipeline } from "utils/pipeline";
import {
  Metadata,
  MasterEdition,
  Creator,
  CreateMetadataV2,
  CreateMasterEditionV3,
  DataV2,
  MetadataProgram,
} from "@metaplex-foundation/mpl-token-metadata";
import { ECollectionProgress } from "views/CreateCollection/CreateCollection.state";
import { approveCollectionAuthority } from "./approveCollectionAuthority";
import { NFTStorage } from "nft.storage";

const { sendTransaction, prepareTokenAccountAndMintTxs } = actions;

export interface MintArweaweNFTResponse {
  txId: string;
  mint: PublicKey;
  metadata: PublicKey;
}

export interface IMintArweaveParams {
  connection: Connection;
  wallet: Wallet;
  storage: NFTStorage;
  file: File;
  metadata: MetadataJson;
  updateProgress?: (status: ECollectionProgress | null) => void;
}

export async function mintCollectionNFT(
  {
    connection,
    wallet,
    file,
    metadata,
    storage,
    updateProgress = () => {},
  }: IMintArweaveParams,
  WebFile: typeof File = File
): Promise<MintArweaweNFTResponse> {
  const pipe = new Pipeline<ECollectionProgress | null>(null, updateProgress);

  try {
    pipe.setStep(ECollectionProgress.minting);

    const {
      mint,
      files,
      createMintTx,
      createAssociatedTokenAccountTx,
      mintToTx,
      metadataPDA,
    } = await pipe.exec(async () => {
      const fileMetadata = createFilePack(
        metadata,
        METADATA_FILE_NAME,
        WebFile
      );
      const files = file ? [file, fileMetadata] : [fileMetadata];

      const { mint, createMintTx, createAssociatedTokenAccountTx, mintToTx } =
        await prepareTokenAccountAndMintTxs(connection, wallet.publicKey);

      const metadataPDA = await Metadata.getPDA(mint.publicKey);

      return {
        metadataPDA,
        mint,
        files,
        createMintTx,
        createAssociatedTokenAccountTx,
        mintToTx,
      };
    }, ECollectionProgress.preparing_assets);

    // Start the Upload process.
    pipe.setStep(ECollectionProgress.uploading_assets);

    const { assetFile } = await pipe.exec(async () => {
      if (file) {
        const cid = await storage.storeBlob(files[0]);
        return { assetFile: cid };
      }

      return { arweaveResultForAsset: undefined, assetFile: undefined };
    }, ECollectionProgress.uploading_assets);

    const { metadataFile } = await pipe.exec(async () => {
      if (assetFile) {
        const uri = `https://nftstorage.link/ipfs/${assetFile}`;
        metadata.image = uri;
        metadata.properties.files = [{ type: "image/png", uri }];
      }

      const fileMetadata = createFilePack(
        metadata,
        METADATA_FILE_NAME,
        WebFile
      );

      const cid = await storage.storeBlob(fileMetadata);

      return { metadataFile: cid };
    }, ECollectionProgress.uploading_assets);

    if (!metadataFile) {
      pipe.setStep(ECollectionProgress.collection_mint_failed);
      throw new Error("Failed uploading assets.");
    }

    // Start creating transactions for Token Mint.
    pipe.setStep(ECollectionProgress.preparing_token_transactions);

    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    const createMetadataTx = pipe.exec(() => {
      const creators = metadata.properties.creators.map((c) => new Creator(c));
      const uri = `https://nftstorage.link/ipfs/${metadataFile}`;
      const metadataData = new DataV2({
        uri,
        name: metadata.name,
        symbol: metadata.symbol,
        sellerFeeBasisPoints: metadata.seller_fee_basis_points,
        creators,
        collection: null,
        uses: null,
      });

      // Below code is a hack to get around the fact that the reposity can't be upgraded to support latest CreateMetadataV3 from mpl-token-metadata.
      // Upgrading requires updating @solana packages to latest, and removing/migrating @metaplex/js which is a bigger lift than we can do right now.
      // Code source: https://twitter.com/mralbertchen/status/1654288963564355585?s=20
      const {
        instructions: [createMetaDataIxOld],
      } = new CreateMetadataV2(
        {
          feePayer: wallet.publicKey,
        },
        {
          metadata: metadataPDA,
          mint: mint.publicKey,
          mintAuthority: wallet.publicKey,
          updateAuthority: wallet.publicKey,
          metadataData,
        }
      );
      createMetaDataIxOld.data.writeUInt8(33, 0);
      const finalArg = Buffer.alloc(1);
      finalArg.writeUInt8(0, 0);
      const newData = Buffer.concat([createMetaDataIxOld.data, finalArg]);
      const createMetadataV3Ix = new TransactionInstruction({
        programId: MetadataProgram.PUBKEY,
        keys: createMetaDataIxOld.keys,
        data: newData,
      });
      const createMetadataTx = new Transaction().add(createMetadataV3Ix);
      return createMetadataTx;
    }, ECollectionProgress.preparing_token_transactions);

    const createMasterEditionTx = await pipe.exec(async () => {
      const [edition] = await PublicKey.findProgramAddress(
        [
          Buffer.from(MetadataProgram.PREFIX),
          MetadataProgram.PUBKEY.toBuffer(),
          new PublicKey(mint.publicKey).toBuffer(),
          Buffer.from(MasterEdition.EDITION_PREFIX),
        ],
        MetadataProgram.PUBKEY
      );

      return new CreateMasterEditionV3(
        { feePayer: wallet.publicKey },
        {
          edition,
          metadata: metadataPDA,
          updateAuthority: wallet.publicKey,
          mint: mint.publicKey,
          mintAuthority: wallet.publicKey,
          maxSupply: new BN(0),
        }
      );
    }, ECollectionProgress.preparing_token_transactions);

    // Send the transactions to create the NFT
    pipe.setStep(ECollectionProgress.signing_token_transaction);

    const txid = await pipe.exec(
      () =>
        sendTransaction({
          connection,
          wallet,
          signers: [mint],
          txs: [
            createMintTx,
            createMetadataTx,
            createAssociatedTokenAccountTx,
            mintToTx,
            createMasterEditionTx,
          ],
        }),
      ECollectionProgress.signing_token_transaction
    );

    if (!txid) {
      pipe.setStep(ECollectionProgress.collection_mint_failed);
      throw new Error("Failed to mint the Collection NFT.");
    }

    await pipe.exec(
      () => connection.confirmTransaction(txid, "max"),
      ECollectionProgress.sending_transaction_to_solana
    );

    await pipe.exec(
      () => connection.getParsedTransaction(txid, "confirmed"),
      ECollectionProgress.waiting_for_initial_confirmation
    );

    const approveUpdateAuthorityTx = await approveCollectionAuthority({
      connection,
      wallet,
      mint: mint.publicKey,
      pipe,
    });

    if (!approveUpdateAuthorityTx) {
      pipe.setStep(ECollectionProgress.failed_to_set_collection_authority);
      throw new Error("Error adding an Approved Collection Authority.");
    }

    return {
      txId: txid,
      mint: mint.publicKey,
      metadata: metadataPDA,
    };
  } catch (err) {
    throw err;
  }
}
