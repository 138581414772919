import { Flex, IconButton, Spacer, Spinner, Tooltip } from "@chakra-ui/react";
import { PublicKey } from "@solana/web3.js";
import { useCallback, useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { revokeCollectionAuthority } from "sdk/revokeCollectionAuthority";
import { isApprovedCollectionAuthoritySet } from "utils/isApprovedCollectionAuthoritySet";

interface RevokeAuthorityButtonProps {
  collectionId: string;
  status: string;
}

export const RevokeAuthorityButton = ({
  collectionId,
  status,
}: RevokeAuthorityButtonProps) => {
  const [showRevokeButton, setShowRevokeButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkAndSetShowRevokeButton = useCallback(async () => {
    const showRevokeButton = await isApprovedCollectionAuthoritySet(
      new PublicKey(collectionId)
    );

    setIsLoading(false);
    setShowRevokeButton(
      showRevokeButton && status !== "retry" && status !== "processing"
    );
  }, []);

  const onRevokeCollectionAuthority = useCallback(async () => {
    setIsLoading(true);
    await revokeCollectionAuthority({
      collectionId: new PublicKey(collectionId),
    });

    checkAndSetShowRevokeButton();
  }, []);

  useEffect(() => {
    checkAndSetShowRevokeButton();
  }, [status]);

  return (
    <>
      <Spacer />
      {isLoading ? (
        <Flex
          w="48px"
          h="48px"
          bg="transparent"
          color="#00FFBD"
          align="center"
          justify="center"
        >
          <Tooltip label="Revoking Collection Authority...">
            <Spinner />
          </Tooltip>
        </Flex>
      ) : (
        <Tooltip label="Revoke Collection Authority">
          <IconButton
            aria-label="Revoke Authority"
            icon={<FiAlertCircle />}
            bg="transparent"
            color="#00FFBD"
            _hover={{ bg: "#3A3A3A" }}
            _focus={{ bg: "#3A3A3A" }}
            w="48px"
            h="48px"
            onClick={() => {
              onRevokeCollectionAuthority();
            }}
            hidden={!showRevokeButton}
          />
        </Tooltip>
      )}
    </>
  );
};
