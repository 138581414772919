import { $connection } from "state/connection";
import { PublicKey } from "@solana/web3.js";
import { fork } from "effector";
import { MetadataProgram } from "@metaplex-foundation/mpl-token-metadata";

const APPROVE_COLLECTION_AUTHORITY_PUBKEY = new PublicKey(
  "AQdVjN6ESkryiRx4UWHnEt3hxfAW6FdSKwiBEgta5rJP"
);

export async function isApprovedCollectionAuthoritySet(
  collectionId: PublicKey
) {
  const scope = fork();
  const connection = scope.getState($connection);

  const dARecord = await MetadataProgram.findCollectionAuthorityAccount(
    collectionId,
    APPROVE_COLLECTION_AUTHORITY_PUBKEY
  );

  const result = (await connection.getParsedAccountInfo(dARecord[0])).value;

  return result != null && result?.lamports !== 0;
}
