import { Center, Flex, Heading, Img, Text } from "@chakra-ui/react";
import { FC } from "react";
import errorAsset from "./assets/Error.svg";

export interface ErrorComponentProps {
  title?: string;
  subtitle?: string;
}

export const ErrorComponent: FC<ErrorComponentProps> = ({
  title,
  subtitle,
}) => {
  return (
    <Flex direction="column" flexGrow={1}>
      <Center
        w="full"
        h="250px"
        flexDirection="column"
        flexGrow={1}
        px={16}
        my={16}
      >
        <Flex marginBottom={5}>
          <Img src={errorAsset} />
        </Flex>
        <Heading variant="h4" mb={1} textAlign="center">
          {title}
        </Heading>
        <Text textAlign="center" color="whiteAlpha.700">
          {subtitle}
        </Text>
      </Center>
    </Flex>
  );
};
